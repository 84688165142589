import Head, { HeadProps } from "@crafthunt-ui/Head";
import { NavbarCTADef } from "@crafthunt-ui/NavbarCTA/NavbarCTA";
import dynamic from "next/dynamic";
import { ReactNode } from "react";
import { StoryblokSettingsDef } from "types/storyblok.types";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";

const DynamicJobSearchOrHireDialog = dynamic(
  () =>
    import("components/ui/JobSearchOrHireDialog/JobSearchOrHireDialog").then(
      (mod) => mod.JobSearchOrHireDialog
    ),
  {
    ssr: false,
  }
);

export type LayoutProps = {
  children?: ReactNode;
  settings?: StoryblokSettingsDef;
  hideNav?: boolean;
  customNavbarAction?: NavbarCTADef[];
  isCompanyPage?: boolean;
  hideBanner?: boolean;
  canonicalSlug?: string;
} & HeadProps;

const Layout = ({
  children,
  locale,
  meta,
  settings,
  translatedSlugs,
  hideFromRobots,
  hideNav = false,
  customNavbarAction,
  isCompanyPage,
  hideBanner,
  canonicalSlug,
}: LayoutProps) => {
  return (
    <div className="bg-gray-900">
      <Head
        meta={meta}
        locale={locale}
        translatedSlugs={translatedSlugs}
        hideFromRobots={hideFromRobots}
        canonicalSlug={canonicalSlug}
      />
      <Navigation
        locale={locale}
        settings={settings}
        translatedSlugs={translatedSlugs}
        hideNav={hideNav}
        customNavbarAction={customNavbarAction}
        isCompanyPage={isCompanyPage}
        hideBanner={hideBanner}
      />
      {isCompanyPage && <DynamicJobSearchOrHireDialog />}
      <main>{children}</main>
      <Footer
        settings={settings}
        translatedSlugs={translatedSlugs}
        isCompanyPage={isCompanyPage}
      />
    </div>
  );
};

export default Layout;
